import {SiteLogin, IObtainTokenState, IObtainTokenOAuthConfig} from "../store/devPortal/types";

const init: IObtainTokenState = {
    oauth_config: {} as IObtainTokenOAuthConfig,
    access_token: "",
    refresh_token: ""
};

export function site_login(state: IObtainTokenState = init, action: any): IObtainTokenState {
    switch (action.type) {
        case SiteLogin.SUCCESS:
            return {
                ...state,
                access_token: action.access_token,
                refresh_token: action.refresh_token
            };
        default:
            return state;
    }
};
