import {IObtainTokenOAuthConfig} from "../store/devPortal/types";

export function generateCognitoHostedLoginURL(oauthConfig: IObtainTokenOAuthConfig, redirectUri: string): string {

    return oauthConfig.auth_base_url
        + '/login?'
        + 'client_id=' + oauthConfig.client_id
        + '&redirect_uri=' + redirectUri
        + '&response_type=code'
        + '&scope=' + oauthConfig.scopes + ' aws.cognito.signin.user.admin';

}

export function generateCognitoHostedAuthorizeURL(oauthConfig: IObtainTokenOAuthConfig, redirectUri: string): string {

    return oauthConfig.auth_base_url
        + '/oauth2/authorize?'
        + 'client_id=' + oauthConfig.client_id
        + '&redirect_uri=' + redirectUri
        + '&response_type=code';
}

export function generateRedirectURI(route: string): string {
    const parsedUrl = new URL(window.location.href);
    const baseUrl = parsedUrl.origin;

    return baseUrl + route;
}

export function getLoginOAuthConfig(): IObtainTokenOAuthConfig {
    return {
        auth_base_url: "https://finance-api-developer-portal.auth.us-east-1.amazoncognito.com",
        client_id: "77m44n5u59m4a4cahjbr4qmr9r",
        client_secret: "",
        scopes: ""
    };
}