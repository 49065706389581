import React from 'react';

import {IObtainTokenState, IRootState} from "./store/devPortal/types";
import {ThunkDispatch} from "redux-thunk";
import {loginToSite} from "./actions/login_actions";
import {connect} from "react-redux";

const Home: React.FC<ReduxType> = (props:ReduxType) => {

    //const oauthConfig = getLoginOAuthConfig();

    //const redirectUri = generateRedirectURI('/auth/site-login');
    //const loginUrl = generateCognitoHostedAuthorizeURL(oauthConfig, redirectUri);

    return <div>

            <h2>The Sandbox Environment</h2>
            <p>Ninth Wave provides its Sandbox environment and tooling as a courtesy to allow developers
                to familiarize themselves with the Ninth Wave APIs. These environments are not intended for performance
                testing, and should not be used for that purpose. Please be courteous to others using the system.
            </p>

            <p>If you do not currently have a business relationship with Ninth Wave, please fill out
                our <a target="inquiry_form" href="https://ninth-wave.com/contact-us/">inquiry form</a>, and we will contact you to address your needs.
            </p>
    </div>;

};


const mapStateToProps = ({login} : IRootState) => {
    return { ...login };
};

const mapDispatcherToProps = (dispatch: ThunkDispatch<{}, {}, any>, ownProps: any): any => {
    return {
        loginToSite: async (oauthCfg: IObtainTokenState) => {
            await dispatch(loginToSite(oauthCfg))
        }
    }
};

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatcherToProps>;

export default connect(mapStateToProps, mapDispatcherToProps)(Home);
