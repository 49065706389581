import React from 'react';
import { ThunkDispatch } from 'redux-thunk'
import {connect} from "react-redux";
import * as queryString from "query-string";
import {handleObtainTokenAuthCode} from "../../actions/tools_obtain-token_actions";
import { Redirect } from "react-router-dom";
import {IRootState} from "../../store/devPortal/types";

class CallbackHandler extends React.Component<ReduxType> {

    componentDidMount(): void {
        let query = window.location.search;
        const parsed = queryString.parseUrl(query);
        this.props.handleObtainTokenAuthCode(parsed.query.code);
    }

    public render() {
        let redirect: any = "";

        if (this.props.access_token) {
            redirect = <Redirect to="/tools/obtain-token"></Redirect>;
        }

        return (
            <div>
                <h1>Authentication code received. Exchanging for tokens...</h1>
                {redirect}
            </div>
        );
    }
}

const mapStateToProps = ({obtain_token}: IRootState) => {
    return { ...obtain_token };
};

const mapDispatcherToProps = (dispatch: ThunkDispatch<{}, {}, any>, ownProps: any): any => {
    return {
        handleObtainTokenAuthCode: async (auth_code: String) => {
            await dispatch(handleObtainTokenAuthCode(auth_code))
        }
    }
};

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatcherToProps>;

export default connect(mapStateToProps, mapDispatcherToProps)(CallbackHandler);