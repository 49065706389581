import {SiteLogin, IObtainTokenState, IObtainTokenOAuthConfig} from "../store/devPortal/types";
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux';
import axios from "axios";
import * as queryString from "query-string";
import {getLoginOAuthConfig} from "../lib/oauth2";

export const siteLoginSuccess = (tokenData: IObtainTokenState) => ({
    type: SiteLogin.SUCCESS,
    access_token: tokenData.access_token,
    refresh_token: tokenData.refresh_token
});

export function loginToSite(tokenData: IObtainTokenState) {
    return { type: SiteLogin.SUCCESS, access_token: tokenData.access_token, refresh_token: tokenData.refresh_token}
}

export function handleLoginAuthCode(auth_code: String ): ThunkAction<Promise<void>, {}, {}, AnyAction> {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
        return new Promise<void>((resolve) => {

            const oauthConfig = getLoginOAuthConfig();

            console.log("oauthConfig: ", oauthConfig);
            const parsedUrl = new URL(window.location.href);
            const baseUrl = parsedUrl.origin;

            const redirectUri = baseUrl + '/auth/site-login';

            const url = oauthConfig.auth_base_url + '/oauth2/token';

            const form = {
                'grant_type': 'authorization_code',
                'client_id': oauthConfig.client_id.toString().trim(),
                'redirect_uri': redirectUri.trim(),
                'code': auth_code.toString().trim()
            };

            axios.interceptors.request.use(request => {
                console.log('Starting Request', request)
                return request
            });

            axios.interceptors.response.use(response => {
                console.log('Response:', response)
                return response
            });

            axios.post(
                url,
                queryString.stringify(form),
                {
                    headers: {
                        //'authorization': 'Basic ' + btoa(oauthConfig.client_id + ':' + oauthConfig.client_secret),
                        'content-type': 'application/x-www-form-urlencoded'
                    }
                }
            ).then(
                function (response) {
                    console.log(response);
                    const tokenData = {
                        oauth_config: {} as IObtainTokenOAuthConfig,
                        id_token: response.data.id_token,
                        access_token: response.data.access_token,
                        refresh_token: response.data.refresh_token
                    };
                    console.log("Setting sessionStorage site-login: ", tokenData);
                    sessionStorage.setItem('site-login', JSON.stringify(tokenData));
                    dispatch(loginToSite(tokenData));
                }
            ).catch(function (error) {
                console.log(error.response);
            });

            resolve()
        })
    }
}