import {applyMiddleware, compose, createStore} from 'redux';
import { devPortal } from '../reducers';
import {IRootState} from "./devPortal/types";
import thunk from 'redux-thunk';


declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    }
}
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore<IRootState, any, any, any>(
    devPortal,
    compose(
        applyMiddleware(thunk),
        composeEnhancers()
    )
);

export default store;