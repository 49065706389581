import {ActionType} from "typesafe-actions";
import * as loginActions from '../../actions/login_actions';

export interface IObtainTokenState {
    oauth_config?: IObtainTokenOAuthConfig,
    id_token?: String,
    access_token: String,
    refresh_token: String
}

export interface ILoginCredentials {
    user_id?: String,
    password?: String
}

export interface IRootState {
    login: IObtainTokenState,
    obtain_token: IObtainTokenState
}

export enum SiteLogin {
    SUCCESS = 'LOGIN_SUCCESS'
}

export enum ObtainToken {
    FORM_SUBMITTED = 'OBTAIN_TOKEN_FORM_SUBMITTED',
    AUTH_CODE_RECEIVED = 'OBTAIN_TOKEN_AUTH_CODE_RECEIVED',
    AUTH_CODE_EXCHANGE_SUCCESS = 'OBTAIN_TOKEN_AUTH_CODE_EXCHANGE_SUCCESS'
}

export interface IObtainTokenOAuthConfig {
    auth_base_url: String,
    client_id: String,
    client_secret: String,
    scopes: String
}


export type LoginActions = ActionType<typeof loginActions>;