import React from 'react';
import { Form, Input, Button } from 'antd';
import {IObtainTokenOAuthConfig, IRootState} from "../../store/devPortal/types";
import { ThunkDispatch } from 'redux-thunk'
import {connect} from "react-redux";
import {handleObtainTokenLogin} from "../../actions/tools_obtain-token_actions";
import TextArea from "antd/lib/input/TextArea";
import Card from "antd/lib/card";

const layout = {
    labelCol: {
        span: 5,
    },
    wrapperCol: {
        span: 16,
    },
};
const tailLayout = {
    wrapperCol: {
        offset: 5,
        span: 8,
    },
};



class ObtainToken extends React.Component<ReduxType> {

    constructor(props: ReduxType) {
        super(props);

        this.onFinish = this.onFinish.bind(this);
        this.onFinishFailed = this.onFinishFailed.bind(this);

        // This has to be done here. componentDidMount is too late, because the Form initialValues
        // would be set AFTER form initialization. So even though the 'initialValues' is set in the React component
        // inspector, the field values will not be populated.
        const previousValuesJSON = localStorage.getItem('latest-obtain-token-form') || "{}";
        this.state = JSON.parse(previousValuesJSON) as IObtainTokenOAuthConfig;

    }

    onFinish(values: any) {
        console.log('Success:', values);

        this.props.handleObtainTokenLogin(values as IObtainTokenOAuthConfig);
    };

    onFinishFailed(errorInfo: any) {
        console.log('Failed:', errorInfo);
    };

    public render() {

        return (
            <div>

                <p>Enter the OAuth information you were given during your onboarding and press submit. You will be
                redirected to a login form, where you should enter the test user login credentials. If you
                instead receive an error message on the login screen, please ensure that the information you
                entered here is correct. If you continue to have problems, please reach out to your Ninth Wave
                    technical liason.</p>

                <Card title="Obtain OAuth Token Tool" style={{marginTop: '25px'}}>


                <Form
                    {...layout}
                    name="obtain_token_oauth_config"
                    initialValues={{
                        ...this.state
                    }}
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                >
                    <Form.Item
                        label="Base URL"
                        name="auth_base_url"
                        rules={[
                            {
                                required: true,
                                message: 'Please provide Authentication Base URL',
                            },
                        ]}
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item
                        label="Client ID"
                        name="client_id"
                        rules={[
                            {
                                required: true,
                                message: 'Please provide OAuth Client ID',
                            },
                        ]}
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item
                        label="Client Secret"
                        name="client_secret"
                        rules={[
                            {
                                required: true,
                                message: 'Please provide OAuth Client Secret',
                            },
                        ]}
                    >
                        <Input.Password/>
                    </Form.Item>

                    <Form.Item
                        label="OAuth Scopes"
                        name="scopes"
                        rules={[
                            {
                                required: true,
                                message: 'Please provide OAuth Client ID',
                            },
                        ]}
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item {...tailLayout}>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>

                <h2>Retrieved Tokens</h2>

                <h3>Access Token</h3>
                <Form.Item><TextArea value={this.props.access_token} /></Form.Item>

                <h3>Refresh Token</h3>
                <Form.Item><TextArea value={this.props.refresh_token} /></Form.Item>

                </Card>

            </div>

        );
    }
}

const mapStateToProps = ({obtain_token} : IRootState) => {
    return { ...obtain_token };
};

const mapDispatcherToProps = (dispatch: ThunkDispatch<{}, {}, any>, ownProps: any): any => {
    return {
        handleObtainTokenLogin: async (oauthCfg: IObtainTokenOAuthConfig) => {
            await dispatch(handleObtainTokenLogin(oauthCfg))
        }
    }
};

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatcherToProps>;

export default connect(mapStateToProps, mapDispatcherToProps)(ObtainToken);