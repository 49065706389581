import React from 'react';
import './App.css';
import {Layout, Menu} from 'antd';

import logo from "./logo2x.png";

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link, NavLink
} from "react-router-dom";
import ObtainToken from "./tools/obtain-token/ObtainToken";
import CallbackHandler from "./tools/obtain-token/CallbackHandler";
import LoginCallbackHandler from "./login/LoginCallbackHandler";
import {IObtainTokenState, IRootState} from "./store/devPortal/types";
import {ThunkDispatch} from "redux-thunk";
import {connect} from "react-redux";
import {loginToSite} from "./actions/login_actions";
import Home from "./Home";

const {SubMenu} = Menu;
const {Header, Content, Footer, Sider} = Layout;


function App(props: ReduxType) {

    if (! props.access_token) {
        const tokenData = sessionStorage.getItem('site-login');
        if(tokenData) {
            props.loginToSite(tokenData);
        }
    }

    console.log(window.location.pathname);

    let menuKey = window.location.pathname;
    if (window.location.pathname === '/auth/callback') {
        menuKey = '/tools/obtain-token';
    }

    return (
        <div className="App">
            <Router>

            <Layout>
                <Header className="header">
                    <img style={{width: 'auto', height: '30px', display: 'inline-block'}} alt={"logo"} src={logo}/>
                    <h2 style={{color: "white", display: "inline-block", marginLeft: "10px"}}>Ninth Wave Developer Portal</h2>
                </Header>
                <Content style={{padding: '0 50px'}}>
                    <Layout className="site-layout-background" style={{padding: '24px 0'}}>
                        <Sider className="site-layout-background" width={200}>
                            <Menu
                                mode="inline"
                                defaultSelectedKeys={[menuKey]}
                                defaultOpenKeys={['/tools']}
                                style={{height: '100%'}}
                            >
                                <Menu.Item key="/"><Link to="/">Home</Link></Menu.Item>

                                <SubMenu
                                    key="/tools"
                                    title={
                                        <span>
                                          Tools
                                        </span>
                                    }
                                >
                                    <Menu.Item key="/tools/obtain-token"><NavLink to="/tools/obtain-token">Obtain OAuth
                                        Token</NavLink></Menu.Item>
                                </SubMenu>

                            </Menu>

                        </Sider>
                        <Content style={{padding: '0 24px', minHeight: 280}}>

                            <Switch>
                                <Route path="/auth/site-login">
                                    <LoginCallbackHandler />
                                </Route>

                                < Route path="/auth/callback">
                                    <CallbackHandler />
                                </Route>

                                <Route path="/tools/obtain-token">
                                    <ObtainToken/>
                                </Route>

                                <Route path="/">
                                    <Home />
                                </Route>

                            </Switch>


                        </Content>
                    </Layout>
                </Content>
                <Footer style={{textAlign: 'center'}}>&copy; 2020 Ninth Wave - All rights reserved</Footer>
            </Layout>

         </Router>

        </div>
    );
}

const mapStateToProps = ({login} : IRootState) => {
    return { ...login };
};

const mapDispatcherToProps = (dispatch: ThunkDispatch<{}, {}, any>, ownProps: any): any => {
    return {
        loginToSite: async (oauthCfg: IObtainTokenState) => {
            await dispatch(loginToSite(oauthCfg))
        }
    }
};

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatcherToProps>;

export default connect(mapStateToProps, mapDispatcherToProps)(App);

//export default App;
