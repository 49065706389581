import React from 'react';
import { ThunkDispatch } from 'redux-thunk'
import {connect} from "react-redux";
import * as queryString from "query-string";
import { Redirect } from "react-router-dom";
import {IRootState} from "../store/devPortal/types";
import {handleLoginAuthCode} from "../actions/login_actions";

class LoginCallbackHandler extends React.Component<ReduxType> {

    componentDidMount(): void {
        let query = window.location.search;
        const parsed = queryString.parseUrl(query);
        console.log("Parsed code: ", parsed.query.code);
        this.props.handleLoginAuthCode(parsed.query.code);
    }

    public render() {
        let redirect: any = "";

        if (this.props.access_token) {
            redirect = <Redirect to="/"></Redirect>;
        }

        return (
            <div>
                <h1>LOGGING YOU IN</h1>

                {redirect}
            </div>
        );
    }
}

const mapStateToProps = ({login}: IRootState) => {
    return { ...login };
};

const mapDispatcherToProps = (dispatch: ThunkDispatch<{}, {}, any>, ownProps: any): any => {
    return {
        handleLoginAuthCode: async (auth_code: String) => {
            await dispatch(handleLoginAuthCode(auth_code))
        }
    }
};

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatcherToProps>;

export default connect(mapStateToProps, mapDispatcherToProps)(LoginCallbackHandler);