import {
    IObtainTokenOAuthConfig, IObtainTokenState,
    ObtainToken
} from "../store/devPortal/types";
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux';
import axios from 'axios';
import * as queryString from "query-string";
import {generateCognitoHostedLoginURL, generateRedirectURI} from "../lib/oauth2";

export interface IObtainTokenAuthCodeReceivedAction {
    type: ObtainToken,
    oauth_config: IObtainTokenOAuthConfig,
    auth_code: String
}

export function obtainTokenAuthCodeReceived(oauthConfig: IObtainTokenOAuthConfig, auth_code: String): IObtainTokenAuthCodeReceivedAction {
    return {
        type: ObtainToken.AUTH_CODE_RECEIVED,
        oauth_config: {
            ...oauthConfig
        },
        auth_code: auth_code
    }
}

export interface IObtainTokenAuthCodeExchangedAction {
    type: ObtainToken,
    oauth_config: IObtainTokenOAuthConfig,
    access_token: string,
    refresh_token: string
}

export function obtainTokenAuthCodeExchanged(codes: IObtainTokenState) {
    return {
        type: ObtainToken.AUTH_CODE_EXCHANGE_SUCCESS,
        oauth_config: {...codes.oauth_config},
        access_token: codes.access_token,
        refresh_token: codes.refresh_token
    }
}

// Thunks

export function handleObtainTokenLogin(oauthConfig: IObtainTokenOAuthConfig): ThunkAction<Promise<void>, {}, {}, AnyAction> {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
        return new Promise<void>((resolve) => {
            localStorage.setItem("latest-obtain-token-form", JSON.stringify(oauthConfig));

            const redirectUri = generateRedirectURI('/auth/callback');
            const url = generateCognitoHostedLoginURL(oauthConfig, redirectUri);

            console.log("Redirecting to: ", url);

            window.location.href = url;

            resolve()
        })
    }
}

//  http://localhost:3000/auth/callback?code=c0621669-f968-44ae-ab4e-11589bf92390&state=FOO

export function handleObtainTokenAuthCode(auth_code: String ): ThunkAction<Promise<void>, {}, {}, AnyAction> {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
        return new Promise<void>((resolve) => {
            let oauthConfig: IObtainTokenOAuthConfig = JSON.parse(localStorage.getItem("latest-obtain-token-form") || '{}') ;
            console.log("oauthConfig: ", oauthConfig);
            const parsedUrl = new URL(window.location.href);
            const baseUrl = parsedUrl.origin;

            const redirectUri = baseUrl + '/auth/callback';

            const url = oauthConfig.auth_base_url + '/oauth2/token';

            const form = {
                'grant_type': 'authorization_code',
                'client_id': oauthConfig.client_id.toString().trim(),
                'scope': oauthConfig.scopes.toString().trim(),
                'redirect_uri': redirectUri.trim(),
                'code': auth_code.toString().trim(),
            };

            axios.interceptors.request.use(request => {
                console.log('Starting Request', request)
                return request
            });

            axios.interceptors.response.use(response => {
                console.log('Response:', response)
                return response
            });

            axios.post(
                url,
                queryString.stringify(form),
                {
                    headers: {
                        'authorization': 'Basic ' + btoa(oauthConfig.client_id + ':' + oauthConfig.client_secret),
                        'content-type': 'application/x-www-form-urlencoded'
                    }
                }
            ).then(
                function (response) {
                    console.log(response);
                    const state = {oauth_config: oauthConfig, access_token: response.data.access_token, refresh_token: response.data.refresh_token};
                    localStorage.setItem('obtain-token-last-successful-tokens', JSON.stringify(state));
                    dispatch(obtainTokenAuthCodeExchanged(state))
                }
            ).catch(function (error) {
                console.log(error.response);
            });

            resolve()
        })
    }
}