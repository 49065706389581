import {IObtainTokenState, ObtainToken} from "../store/devPortal/types";

const init: IObtainTokenState = {
    oauth_config: {
        client_id: "",
        client_secret: "",
        auth_base_url: "",
        scopes: ""
    },
    access_token: "",
    refresh_token: ""
};

export function obtain_token(state: IObtainTokenState = init, action: any)  {
    switch (action.type) {
        case ObtainToken.AUTH_CODE_EXCHANGE_SUCCESS:
            return {
                ...state,
                oauth_config: { ...action.oauth_config },
                access_token: action.access_token,
                refresh_token: action.refresh_token
            };
        default:
            return state;
    }
};

