import {combineReducers} from 'redux';

import { site_login} from './site_login';
import {IRootState} from "../store/devPortal/types";
import {obtain_token} from "./tools_obtain-token";

export const devPortal = combineReducers<IRootState>({
        login: site_login,
        obtain_token: obtain_token
});

export default devPortal;